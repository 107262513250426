import React from "react"
import styles from "./../../scss/components/BackToLive/_Event.module.scss"
import foto from "./../../images/BackToLive/artist_test.png"
import LocationIcon from "./../../images/BackToLive/icons/location.svg"
import ScheduleIcon from "./../../images/BackToLive/icons/time.svg"
import data from "./utils/event.json"
export default function Event({ handleToForm, isForm, selectedEvent }) {
  return (
    <div className={styles.container}>
      {!isForm ? (
        <>
          <div className={styles.firstWrapper}>
            <img src={selectedEvent.url} className={styles.artistImg} />
            <h1 className={styles.artistName}>{selectedEvent.name}</h1>
            <div className={styles.infoWrap}>
              <div className={styles.infoContainer}>
                <img className={styles.iconInfo} src={LocationIcon} />
                <h2 className={styles.locationText}>{selectedEvent.address}</h2>
              </div>
              <div className={styles.infoContainer}>
                <img className={styles.iconInfo} src={ScheduleIcon} />
                <h2 className={styles.scheduleText}>
                  {selectedEvent.schedule}
                </h2>
              </div>
            </div>
          </div>
          {/* BUTTON */}
          <div onClick={handleToForm} className={styles.button}>
            <h2 className={styles.btnText}>PARTICIPÁ POR ENTRADAS</h2>
          </div>
        </>
      ) : null}
    </div>
  )
}
