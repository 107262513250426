import React, { useState } from "react"
import { Formik, Form, Field } from "formik"
import styles from "./../../../scss/components/BackToLive/_StepForm.module.scss"
import * as Yup from "yup"
import { useHistory } from "react-router"

const SignupSchema = Yup.object().shape({
  gendre: Yup.string().required("Este campo es obligatorio"),
})

const CheckboxInput = ({ name, label, isChecked, setIsChecked, isMobile }) => {
  const toggleCheck = e => {
    setIsChecked(() => !isChecked)
  }
  return (
    <div>
      <input
        type="checkbox"
        className={styles.termsRadio}
        name={name}
        id={name}
        checked={isChecked}
        onChange={toggleCheck}
      />
      <label htmlFor={name}>{/* <span>{label}</span> */}</label>
    </div>
  )
}



export default function Step4({
  propValues,
  selectedProvince,
  isSafari,
  isMobile,
  selectedEvent,
}) {
  const [isChecked, setIsChecked] = useState(false)
  let history = useHistory()
  return (
    <Formik
      initialValues={{
        favouriteBand: "",
      }}
      validationSchema={SignupSchema}
      onSubmit={values => {
        // same shape as initial values
        console.log("submit")
        let newValues = {
          ...propValues,
          ...values,
          event: selectedEvent.name,
          eventId: selectedEvent.id,
          pdv: selectedEvent.address,
        }
        console.log(newValues)
        let data = {
          name: newValues.fullname,
          email: newValues.email,
          state: newValues.province,
          instagram: newValues.instagram,
          dj: newValues.favouriteDj,
          genre: newValues.gendre,
          band: newValues.favouriteDj,
          event: newValues.event,
          eventId: newValues.eventId,
          pdv: newValues.pdv,
        }

        isChecked
          ? fetch("https://wabudweiser-prd.azurewebsites.net/backtolive", {
              //? fetch("http://localhost:8080/backtolive", {
              method: "POST",
              body: JSON.stringify(data),
              credentials: "include",
              headers: {
                "Content-Type": "application/json",
              },
            }).then(response => {
              if (response.ok) {
                history.push("/backtolive/success")
             
              } else {
                response.text().then(text => {
                  console.error("Error guardando los datos. \n", text)
                })
              }
            })
          : alert("Debes aceptar los terminos y condiciones para continuar")
      }}
    >
      {({ errors, touched }) => (
        <Form className={styles.formContainer2}>
          <div className={styles.fieldContainer4}>
            <h1 className={styles.titleText4}>
              ¿Tu género de música preferida?
            </h1>
            <Field
              component="select"
              className={
                // !isSafari ? styles.inputStyle3 : styles.inputStyle3Safari
                isSafari
                  ? !isMobile
                    ? styles.inputStyle3Safari
                    : styles.inputStyle3SafariMobile
                  : styles.inputStyle3
              }
              id="gendre"
              name="gendre"
            >
              <option selected hidden>
                Selecciona una opción
              </option>
              <option value="ELE">Electrónica</option>
              <option value="HH">Hip Hop</option>
              <option value="IND">Indie</option>
              <option value="CACH">Cachengue</option>
              <option value="ROCK">Rock</option>
              <option value="PUNK">Punk</option>
              <option value="BLU">Blues</option>
              <option value="JAZZ">Jazz</option>
              <option value="RB">R&B</option>
              <option value="MET">Metal</option>
              <option value="POP">Pop</option>
            </Field>
            {/* {errors.favouriteBand && touched.favouriteBand ? (
              <div style={{ color: "#dd1a32" }}>{errors.favouriteBand}</div>
            ) : null} */}
          </div>
          {/* TERMS START */}
          <div
            className={
              isSafari && !isMobile
                ? styles.btnContainerSendSafari
                : styles.btnContainerSend
            }
          >
            <div className={styles.termsContainer}>
              <CheckboxInput
                name="remember-me"
                label="Remember Me"
                isChecked={isChecked}
                setIsChecked={setIsChecked}
              />
              <h4 className={styles.termsText}>
                He leído y acepto&nbsp;
                <a
                  className={styles.link}
                  target="_blank"
                  href="/BBCCBACKTOLIVE.doc"
                >
                  Bases y Condiciones
                </a>
                ,&nbsp;
                <a
                  className={styles.link}
                  target="_blank"
                  href="/PoliticadePrivacidad.pdf"
                >
                  Políticas de Privacidad
                </a>
                &nbsp;y activaciones de marketing.
              </h4>
            </div>
            {/* TERMS END */}
            <button className={styles.btnSend} type="submit">
              Enviar
            </button>
          </div>
        </Form>
      )}
    </Formik>
  )
}
