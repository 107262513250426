import React from "react"
import { Formik, Form, Field } from "formik"
import styles from "./../../../scss/components/BackToLive/_StepForm.module.scss"
import * as Yup from "yup"

const SignupSchema = Yup.object().shape({
  favouriteDj: Yup.string()
    .min(2, "Demasiado Corto!")
    .max(50, "Muy largo!")
    .required("Este campo es obligatorio"),
})

export default function Step2({
  setPage,
  setValues,
  propValues,
  isSafari,
  isMobile,
}) {
  return (
    <Formik
      initialValues={{
        favouriteDj: "",
      }}
      validationSchema={SignupSchema}
      onSubmit={values => {
        // same shape as initial values
        let newValues = { ...propValues, ...values }
        setValues(newValues)
        console.log(values)
        setPage(3)
      }}
    >
      {({ errors, touched }) => (
        <Form className={styles.formContainer2}>
          <div className={styles.fieldContainer2}>
            <h1 className={styles.titleText}>¿Cuál es tu DJ o Banda preferida?</h1>
            <Field
              className={styles.inputStyle2}
              name="favouriteDj"
              placeholder="Escriba aquí"
            />
            {errors.favouriteDj && touched.favouriteDj ? (
              <div style={{ color: "#dd1a32", textAlign: "left" }}>
                {errors.favouriteDj}
              </div>
            ) : null}
          </div>
          <div
            className={
              isSafari && !isMobile
                ? styles.btnContainerSafari
                : styles.btnContainer
            }
          >
            <button className={styles.btnSubmit} type="submit">
              Siguiente
            </button>
          </div>
        </Form>
      )}
    </Formik>
  )
}
